(function() {
  const modalElement = document.querySelector<HTMLDivElement>('.modal')

  modalElement
    .querySelector<HTMLButtonElement>('.modal__close')
    .addEventListener('click', () => modalElement.classList.remove('modal--visible'))

  modalElement
    .addEventListener('click', () => modalElement.classList.remove('modal--visible'))

  modalElement
    .querySelector<HTMLButtonElement>('.modal__dialog')
    .addEventListener('click', (e) => e.stopPropagation())

  function loadScript(url: string) {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    head.appendChild(script)
  }

  function serialize(form: HTMLFormElement) {
    const obj = new URLSearchParams()

    if (!form.checkValidity()) {
      throw Error('Invalid')
    }

    const inputs = form.querySelectorAll<HTMLInputElement>('input')
    inputs.forEach(({ name, value }) => {
      obj.append(name, value)
    })

    return obj
  }

  const anchors = document.querySelectorAll('a[href*="#"]')
  anchors.forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault()

      document.querySelector(anchor.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    })
  })

  const forms = document.querySelectorAll('form')
  forms.forEach(form => {
    form.addEventListener('submit', function(e) {
      e.preventDefault()
      forms.forEach(form => {
        form.querySelector<HTMLButtonElement>('button[type=submit]').disabled = true
      })

      try {
        const serialized = serialize(form)
        loadScript('https://listta.activehosted.com/proc.php?' + serialized + '&jsonp=true')
      } catch (e) {
        (window as any)._show_error(undefined, 'Something went wrong!')
      }
    })
  });

  (window as any)._show_thank_you = function(id, message) {
    modalElement.classList.add('modal--visible')
    const textElement = modalElement.querySelector<HTMLParagraphElement>('.modal__text')
    textElement.innerText = message
  };

  (window as any)._show_error = function(id, message) {
    forms.forEach(form => {
      form.querySelector<HTMLButtonElement>('button[type=submit]').disabled = false

      const errors = form.querySelectorAll<HTMLDivElement>('.form__error')
      errors.forEach(error => {
        error.classList.remove('form__error--hidden')
        error.innerText = message
      })
    })
  }
})();

(function SliderImageLoader() {
  const images = document.querySelectorAll<HTMLImageElement>('.slider__item img')
  const isWebpSupports = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0

  images.forEach(function(el, index) {
    const next = images.item(index + 1)

    el.addEventListener('load', function() {
      if (next?.previousElementSibling) {
        const img = new Image()
        if (isWebpSupports) {
          img.src = next.previousElementSibling.getAttribute('srcset')
        } else {
          img.src = next.getAttribute('src')
        }
      }
    })
  })
})()

